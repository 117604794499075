.words {
  margin-top: 0em;

  b {
    display: block; } }

.masthead {
  .logo {
    max-width: 250px; }

  .tagline {
    padding: 0 calc(25vw);
    font-size: 2em; } }
