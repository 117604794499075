#maintenance {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  background: radial-gradient(circle, rgba($black, 0) 0%, rgba($black, 1) 75%), url('../img/maintenance-bgc.jpg') !important;
  background-position: center !important;
  background-size: cover; }

.contacts {
  position: absolute;
  right: 10px;
  bottom: 5px; }

//Helper classes
.upc {
  text-transform: uppercase; }

.lwc {
  text-transform: lowercase; }

.underlined {
  display: inline-block;
  position: relative;
  margin-bottom: 3rem;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -1.5rem;
    right: 2px;
    width: 33%;
    height: 2px;
    background-color: $primary-color; }

  &.nowrap {
    white-space: nowrap; }

  &.centered:after {
    right: 50%;
    transform: translateX(50%); }

  &.left-aligned:after {
    right: auto;
    left: 2px; }

  &.black:after {
    background-color: $black; } }


#wrap {
  position: relative;
  width: 100%;
  overflow: hidden; }

.diag__top,
.diag__bottom {
  display: block;
  position: relative;
  z-index: 1;

  &:before, &:after {
    content: "";
    display: block;
    position: absolute; } }

.diag__top {
  &:before {
    top: -49px;
    left: -1vw;
    right: 0;
    border-top: 50px solid transparent;
    border-left: 0px solid transparent;
    border-right: 101vw solid $black;
    border-bottom: 0px solid transparent; } }

.diag__bottom {
  &:before {
    bottom: -49px;
    left: -1vw;
    right: 0;
    border-top: 50px solid $black;
    border-left: 101vw solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 0px solid transparent; } }

//Buttons
.button {
  font-weight: bold;

  &.hollow {
    position: relative;
    overflow: hidden;
    border-width: 2px !important;
    color: $white !important;
    text-shadow: 0 0 0 $black;
    transition: text-shadow 450ms;

    span {
      position: relative;
      z-index: 1; }

    &:after, &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      will-change: initial;
      top: 0;
      display: block;
      background-color: $primary-color;
      transition: transform 400ms ease, background-color 650ms ease; }

    &:after {
      right: -25%;
      transform: skew(-35deg) translateX(100%); }

    &:before {
      left: -25%;
      transform: skew(-35deg) translateX(-100%); }

    &:hover {
      text-shadow: 0px 2px 6px $black;

      &:after, &:before {
        background-color: $button-background-hover; }

      &:after {
        transform: skew(-35deg) translateX(-10%); }

      &:before {
        transform: skew(-35deg) translateX(10%); } } } }


.logo {
  text-align: center;

  img {
    max-width: 150px; } }

.words {
  margin-top: 1.5em;
  text-align: center; }

// Language block
.lang__selection {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
  text-align: right;
  opacity: 0;

  .leaf {
    display: inline-block;

    a {
      padding: 0 .25em;
      color: $white; }

    &.active {
      a {
        display: block;
        color: darken($primary-color, 30); } } } }

//Topbar
#header {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 99;
  transition: 220ms ease-out;
  pointer-events: none;

  &.visible {
    background-color: rgba($black, 0.5);
    pointer-events: auto;

    .main__menu {
      opacity: 1; }

    .lang__selection {
      opacity: 1; } }

  .logo {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px; } }

.logo__small {
  display: block;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%); }

.main__menu {
  opacity: 0;
  transition: 200ms ease-out 50ms;

  ul {
    margin: 0;
    padding: 1em 0; }

  li {
    display: block;
    text-transform: lowercase;
    padding: 1em;
    font-weight: bold; } }

// Hamburger mobile
#hamburger {
  display: block;
  position: fixed;
  top: 0px;
  margin: 15px;
  left: 0px;
  width: 30px;
  height: 30px;
  transform: rotate(0deg);
  transition: transform .5s ease-in-out;
  cursor: pointer;
  z-index: 99;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: darken($primary-color, 15);
    border-radius: 0px;
    opacity: 1;
    left: 0; }

  span:nth-child(1) {
    top: 0px;
    transform-origin: 50%, 50%;
    transition: top 150ms linear 250ms, transform 250ms ease 0ms, background-color 350ms ease 600ms; }


  span:nth-child(2) {
    top: 7px;
    width: 100%;
    margin: 0px 0% 0px;
    transition: background-color 350ms ease 600ms; }

  span:nth-child(3) {
    top: 14px;
    transform-origin: 50%, 50%;
    transition: top 150ms linear 250ms, transform 250ms ease 0ms, background-color 350ms ease 600ms; }

  &.open {
    span {
      background-color: $primary-color; }

    span:nth-child(1) {
      transform: rotate(45deg);
      top: 7px;
      transition: top 150ms linear, transform 250ms ease 150ms, background-color 250ms ease 500ms; }

    span:nth-child(2) {
      width: 0%;
      margin: 0px 50% 0px;
      transition: width 250ms ease-in-out, margin 250ms ease-in-out, background-color 250ms ease 500ms; }

    span:nth-child(3) {
      transform: rotate(-45deg);
      top: 7px;
      transition: top 150ms linear, transform 250ms ease 150ms, background-color 250ms ease 500ms; } } }

.masthead {
  height: 80vh;
  background-position: center !important;
  background-size: cover !important;

  .logo {
    max-width: 150px; }

  .tagline {
    display: inline-block;
    font-size: 1.5em;
    margin-bottom: 5vh;
    margin-top: 5vh; } }

.about_us {
  background-color: $black; }

.about_us, .history {
  padding: 5em 0;

  &.teaser {
    p {
      margin-bottom: 0; } } }

.products {
  position: relative;
  padding: 12vmax 0;
  background-position: center !important;
  background-size: cover;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('../img/bgc.svg'); }

  span.big {
    font-size: 3.5em; }

  h1 {
    display: inline; } }

//Gallery
body {
  .featherlight {
    .featherlight-content {
      background-color: $secondary-color;

      p {
        color: $black; } } } }

.gallery {
  a.button.hollow {
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 300ms ease; }

    &:hover {
      span {
        opacity: 1; } } }

  .item-title {
    color: $primary-color;
    min-height: 2.5em; } }

.clients {
  padding: 5em 0;
  text-align: center;

  img {
    margin-bottom: map-get($grid-column-gutter, medium);
    filter: grayscale(1);

    &:hover {
      filter: grayscale(0); } } }

.history {
  background-color: $secondary-color;

  p {
    color: $black; } }

.certifications {
  padding: 5em 0em 9em 0;
  background-color: $black; }

.upper__footer {
  background-color: $dark-gray;
  padding: 2em 1em;

  &:before {
    border-right: 101vw solid $dark-gray; }

  .footer__menu {
    ul {
      list-style-type: none; }

    .leaf {
      display: inline-block;
      padding: 1em 0;

      a {
        display: inline-block;
        padding: 0 0.5em;
        color: $white;

        &:hover {
            color: $primary-color; } }

      &:after {
        content: " |"; }

      &:last-child:after {
        content: none; } } }

  .info__contacts {
    list-style-type: none;

    .leaf {
      padding: .25em 0;

      a {
        color: $white;

        i {
          color: $primary-color; }

        &:hover {
          color: $primary-color; } } } } }

.bottom__footer {
  padding: 2em 1em;
  text-align: center;

  i {
    color: $primary-color; }

  a {
    color: $white;
    &:hover {
      color: $primary-color; } } }

// Forms
input {
  background-color: transparent; }
