//Topbar
#header {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba($black, 0.5);
  pointer-events: auto;
  z-index: 98;

  .logo {
    display: inline-block;
    position: relative;
    margin: 10px;
    top: auto;
    right: auto; } }

.logo__small {
  display: none; }

// Language block
.lang__selection {
  opacity: 1; }


.main__menu {
  opacity: 1;

  ul {
    margin: 0;
    padding: 0; }

  li {
    display: inline-block;
    text-transform: lowercase;
    padding: 0 1em;
    font-weight: bold; } }

#hamburger {
  display: none; }

.masthead {
  .tagline {
    padding: 0 calc(20vw); } }
